<template lang="html">
  <v-card>
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.perfil', 2) }}</h3>
    </v-card-title>
    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-if="grupos"
      :headers="cabecalho"
      :items="gruposFiltrados"
      :options.sync="pagination"
      :server-items-length="grupos.length"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15],
      }">

      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom v-if="canAccessCRUD">
          <template v-slot:activator="{ on }">
            <v-btn :id="`grupo-tabela-button-edit-${item.name}`" icon class="mx-0" v-on="on" @click="edit(item)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.editar') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!canAccessCRUD">
          <template v-slot:activator="{ on }">
            <v-btn :id="`grupo-tabela-button-view-${item.name}`" icon v-on="on" @click="visualizar(item)">
              <v-icon>search</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.visualizar_perfil') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="canAccessCRUD">
          <template v-slot:activator="{ on }">
            <v-btn :id="`grupo-tabela-button-delete-${item.name}`" icon v-on="on" @click="excluir(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.excluir_perfil') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
    </v-data-table>

    <confirm ref="dialogExcluir"
      :persistent="true"
      :message="$t('message.tem_certeza_exluir_perfil', {param: grupo.name})"
      :title="$t('label.atencao')"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"
      @agree="confirmarExclusao()"
      />
  </v-card>
</template>

<script type="text/javascript">
import { generateComputed } from '@/common/functions/roles-computed-generator';
import { showLoading, hideLoading } from '@/common/functions/loading';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  data() {
    return {
      ssoGrupo: this.$api.ssoGrupo(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      searchQuery: null,
      openSearchToolbar: false,

      gruposFiltrados: [],
      grupos: [],
      grupo: {},

      cabecalho: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '150px',
        },
        { text: this.$tc('label.nome', 1), value: 'name', sortable: true },
      ],
      pagination: {
        itemsPerPage: 10,
      },
    };
  },
  components: {
    Confirm,
  },
  computed: {
    ...generateComputed('Perfil', [
      'canAccessCRUD',
    ]),
    isContentAvailable() {
      return !!this.gruposFiltrados && !!this.gruposFiltrados.length;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    filtrar() {
      if (this.searchQuery) {
        this.pagination.page = 1;
      }

      let filtrados = this.grupos.filter((g) => !this.searchQuery
        || g.name.toLowerCase().includes(this.searchQuery.toLowerCase()));

      this.onSort(filtrados);

      const { page, itemsPerPage } = this.pagination;
      const offset = (page - 1) * itemsPerPage;
      if (filtrados.length > itemsPerPage) {
        filtrados = filtrados.slice(offset, offset + itemsPerPage);
      }

      this.gruposFiltrados = filtrados;
      setTimeout(() => this.$forceUpdate());
    },
    excluir(row) {
      this.grupo = row;
      this.$refs.dialogExcluir.open();
    },
    confirmarExclusao() {
      this.ssoGrupo.excluir({ id: this.grupo.id })
        .then(() => {
          this.$toast(
            this.$t('message.excluido_sucesso', { param: this.$tc('label.perfil', 1) }),
          );
          this.carregar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregar() {
      showLoading();
      this.ssoGrupo.listar()
        .then((response) => {
          this.grupos = response.data;
          this.filtrar();
          setTimeout(() => hideLoading(), 3500);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    onSort(filtrados) {
      const { sortDesc } = this.pagination;

      if (sortDesc.length > 0 && sortDesc[0]) {
        filtrados.sort(this.sortDesc);
        return;
      }

      filtrados.sort(this.sortAsc);
    },
    sortAsc(a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA > nameB) return 1;
      if (nameA < nameB) return -1;
      return 0;
    },
    sortDesc(a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA > nameB) return -1;
      if (nameA < nameB) return 1;
      return 0;
    },
    edit(row) {
      this.$emit('GRUPOTABELA_EDITAR', row);
    },
    visualizar(row) {
      this.$emit('GRUPOTABELA_VISUALIZAR', row);
    },
  },
  mounted() {
    this.carregar();
  },
};
</script>
