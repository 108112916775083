<template>
  <div v-if="canAccessPage">

    <grupo-tabela
      style="outline:none;"
      @GRUPOTABELA_EDITAR="editar"
      @GRUPOTABELA_VISUALIZAR="visualizar"/>

    <v-tooltip left v-if="canAccessCRUD">
      <template v-slot:activator="{ on }">
        <v-btn @click="$router.push({ name: 'novoGrupo' })"
          fixed fab bottom right color="primary" v-on="on" class="v-btn__fab">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>{{$t('label.nova_perfil')}}</span>
    </v-tooltip>

  </div>
</template>

<script>
import { generateComputed } from '@/common/functions/roles-computed-generator';
import GrupoTabela from './GrupoTabela';

export default {
  name: 'GrupoList',
  components: {
    GrupoTabela,
  },
  computed: {
    ...generateComputed('Perfil', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    editar(item) {
      this.$router.push({ name: 'editarGrupo', params: { id: item.id } });
    },
    visualizar(item) {
      this.$router.push({ name: 'verGrupo', params: { id: item.id } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
